import { React } from "react";
import { Grid } from "@material-ui/core";
import { useShopify } from "../hooks";
import Product from "./Product";
import Footer from "./Footer";
import NewNav from "./NewNav";
import { Carousel } from "react-responsive-carousel";

const Placeholder = () => {
  const getNumberOfItems = () => {
    const width = window.innerWidth;

    if (width < 960) return 4;
    if (width < 1280) return 6;
    return 8;
  };

  const numberedArray = Array.from(Array(getNumberOfItems()).keys());

  return numberedArray.map((number) => (
    <Grid item xs={6} md={4} lg={3} key={number}>
      <div className="loading_block" />
    </Grid>
  ));
};

const banners = ["././Assets/banner2.jpg", "././Assets/banner1.jpg"];

const Home = (props) => {
  const { products } = useShopify();

  return (
    <>
      <NewNav />
      <div>
        <div className="App__header">
          <Carousel
            className="App__carousel"
            autoPlay
            infiniteLoop
            interval={7000}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            showArrows={false}
            dynamicHeight={true}
          >
            {banners.map((banner, i) => (
              <img key={i} src={banner} alt={`banner ${i}`} />
            ))}
          </Carousel>
        </div>
        {/* announcement bar below app__header displaying Limited Drop 2 Weeks Only */}
        <div className="App__announcement">
          <h1 className="App__announcement-text">Limited Drop 2 Weeks Only</h1>
        </div>

        <div className="App__title">
          <h1 className="App__title-text">
            The Official Nomad's Way Clothing Store
          </h1>
        </div>
      </div>

      <div className="Products-wrapper">
        <div className="Products-wrapper__inner">
          <Grid container spacing={3}>
            {products && products.length > 0 ? (
              products.map((product, i) => (
                <Grid item xs={6} md={4} lg={3} key={i}>
                  <Product product={product} history={props.history} />
                </Grid>
              ))
            ) : (
              <Placeholder />
            )}
          </Grid>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
